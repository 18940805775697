import { CSpinner } from "@coreui/react";

export const PageLoader = () => {
  return (
    <div className="loader">
      <CSpinner
        color="secondary"
        style={{ marginLeft: "50%", marginTop: "5%" }}
      />
    </div>
  );
};
