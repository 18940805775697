export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  dates: string[];
  systemTempGraph: (number | null)[];
  solarChargeCurrentGraph?: (number | null)[];
  batteryVoltageGraph?: (number | null)[];
  batteryCurrentGraph?: (number | null)[];
  systemState: number;
  batteryVoltageAvg: number;
  batteryVoltage: number;
  batteryAmps: number;
  solarWatts: number;
  solarHours: number;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
